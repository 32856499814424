import styled, { keyframes } from 'styled-components';



const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;


const CustomLoader = ({ size = "40", isWrapper }) => {

  const Spinner = styled.div`
    margin: 16px;
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 4px solid black;
    background: transparent;
    width: ${size}px;
    height: ${size}px;
    border-radius: 50%;
    border-color: #045de8;
  `;

  return (
    <div style={{ padding: isWrapper ? '0px' : '24px' }}>
      <Spinner />
      {!isWrapper && (<div>Please Wait...</div>)}
    </div>
  )
};

export default CustomLoader;