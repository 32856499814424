import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardData: null,
  refreshDashboardData: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    setRefreshDashboardData: (state, action) => {
      state.refreshDashboardData = action.payload;
    },
  },
});

export const { setDashboardData } = dashboardSlice.actions;
export const { setRefreshDashboardData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
