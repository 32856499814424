import './App.css';
import AppRouter from './routes/AppRouter';
import { Worker } from '@react-pdf-viewer/core';
import { ToastContainer, toast } from "react-toastify";

function App() {

  return (
    <div className="App">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <AppRouter />
      </Worker>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
