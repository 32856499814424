import { lazy } from 'react';

const DashboardRoutes = [
  {
    path: "/dashboard-details",
    component: lazy(() => import("../views/pages/dashboardDetails")),
    exact: true,
  },
];

export default DashboardRoutes;
