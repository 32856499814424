import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for the theme
const ThemeContext = createContext();

// Custom hook to use the theme
export const useTheme = () => useContext(ThemeContext);

// ThemeProvider component
export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setIsDarkMode(e.matches);
      console.log(e.matches ? 'User prefers dark mode' : 'User prefers light mode');
    };

    // Set initial state
    handleChange(mediaQuery);

    // Add event listener
    mediaQuery.addListener(handleChange);

    // Clean up
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  return (
    <ThemeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

// A component to apply the theme
export const ThemedApp = ({ children }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={isDarkMode ? 'dark' : 'light'}>
      {children}
    </div>
  );
};