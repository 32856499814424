import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorage } from "../auth/index";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  loading: false,
  error: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
      state.token = action.payload.token;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.error = action.payload;
    },
    logoutStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.loading = false;
    },
    logoutFailure: (state) => {
      state.loading = false;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.loading = false;
      state.error = null;

      // Clear data from local storage if needed
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      localStorage.removeItem('dashboardData');
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } = loginSlice.actions;

// Selectors
const getLocalStorageUserData = getLocalStorage('userData')
const getLocalStorageLoginToken = getLocalStorage('token')
export const selectIsAuthenticated = (state) => state.login.isAuthenticated;
export const selectUser = (state) => state.login.user ? state.login.user : getLocalStorageUserData;
export const selectToken = (state) => state.login.token ? state.login.token : getLocalStorageLoginToken;
export const selectLoading = (state) => state.login.loading;
export const selectError = (state) => state.login.error;

// Reducer
export default loginSlice.reducer;
